import React, { useEffect, useContext } from 'react';
import styled from 'styled-components';
import Layout from '../components/layout';
import SEO from '../components/seo';
import { TimelineLite } from 'gsap/TweenMax';
import Cathode from '../svg/cathode.svg';
import Vivus from 'vivus';
import { GlobalDispatchContext } from '../context/GlobalContextProvider';

const Section1 = styled.section`
  min-height: calc(100vh - 75px);
  position: relative;
  padding-top: 75px;
  width: 100%;
  background-color: #363636;
  display: flex;
  overflow: hidden;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const HeroHeader = styled.h1`
  font-family: 'Open Sans', sans-serif;
  font-weight: 500;
  font-size: 70rem;
  color: #eee;
  opacity: 0;
  margin: 0 0 15px 0;
  position: relative;
  border-left: 2rem solid #eee;
  padding-left: 25px;
  line-height: 1.2;
  grid-column: 1;
  
  @media screen and (max-width: 1440px) {
    font-size: 64rem;
  }
  
  @media screen and (max-width: 1220px) {
    font-size: 54rem;
  }
  
  @media screen and (max-width: 1020px) {
    font-size: 50rem;
  }
  
  @media screen and (max-width: 900px) {
   grid-column: 1 / 3;
  }
  
  @media screen and (max-width: 900px) {
   grid-column: 1 / 3;
  }
`;

const HeroSubheader = styled.h2`
  font-family: 'Lato', sans-serif;
  font-size: 26rem;
  color: #eee;
  opacity: 0;
  margin: 0;
  position: relative;
  grid-column: 1;
  
  @media screen and (max-width: 1440px) {
    font-size: 24rem;
  }
  
  @media screen and (max-width: 1220px) {
    font-size: 22rem;
  }
  
  @media screen and (max-width: 1020px) {
    font-size: 20rem;
  }
  
  @media screen and (max-width: 900px) {
   grid-column: 1 / 3;
  }
`;

const HeroBody = styled.p`
  font-family: 'Lato Light', sans-serif;
  font-weight: 300;
  font-size: 22rem;
  color: #eee;
  padding-bottom: 0;
  opacity: 0;
  position: relative;
  grid-column: 1;
  
  & + & {
    margin-top: 10px;
  }
  
  @media screen and (max-width: 1440px) {
    font-size: 16rem;
  }

  @media screen and (max-width: 1220px) {
    font-size: 14rem;
  }
  
  @media screen and (max-width: 900px) {
    grid-column: 1 / 3;
  }
  
`;

const HeroWrapper = styled.div`
  display: grid;
  position: relative;
  width: 95%;
  margin: auto;
  flex: 0;
  justify-content: center;
  align-items: center;
  grid-template-columns: 700px auto;
  grid-template-rows: auto auto auto auto auto auto;
  row-gap: 5px;
  
  @media screen and (max-width: 1220px) {
    grid-template-columns: 600px auto;
  }
  
  @media screen and (max-width: 1020px) {
    grid-template-columns: 550px auto;
  }
  
  @media screen and (max-width: 900px) {
    grid-template-columns: 1fr 1fr;
  }
  
  @media screen and (max-width: 500px) {
   text-align: center;
  }
`;

const LandingList = styled.ul`
  font-family: 'Lato Light', sans-serif;
  font-weight: 300;
  font-size: 22rem;
  color: #eee;
  padding-left: 10px;
  margin: 10px 0 10px 0;
  list-style-position: inside;
  position: relative;
  grid-column: 1;
  text-align: left;
  
  li {
    padding-bottom: 5px;
    opacity: 0;
  }
  
 @media screen and (max-width: 1440px) {
    font-size: 16rem;
  }

  @media screen and (max-width: 1220px) {
    font-size: 14rem;
  }
  
  @media screen and (max-width: 900px) {
      grid-column: 1 / 3;
  }
`;

const CathodeDiv = styled.div`
  pointer-events: none;
  width: 650px;
  grid-column: 2;
  grid-row: 1 / 7;
  justify-self: flex-end;
  margin-left: 50px;
  position: relative;
  opacity: 0;

  @media screen and (max-width: 1440px) {
    width: 450px;
  }

  @media screen and (max-width: 1220px) {
    width: 350px;
  }
  
  @media screen and (max-width: 1020px) {
    width: 275px;
  }
  
  @media screen and (max-width: 900px) {
    margin: 25px 0;
    width: 300px;
    grid-column: 1 / 3;
    grid-row: 3;
    justify-self: center;
  }
`;


const GraphiteElectrodePage = () => {
  const dispatch = useContext(GlobalDispatchContext);
  const landingElements = [];

  useEffect(() => {
    dispatch({ type: 'NAV_COLOR_CHANGE', color: 'rgba(54, 54, 54, 0.8)' });
    new Vivus('cathode-div',{
        start: 'autostart',
        duration: 75,
        file: Cathode,
      }, null);

    let landingTween = new TimelineLite();
    landingTween.to(landingElements, 1, {
        delay: 0.1,
        autoAlpha: 1,
        overwrite: false,
      },
    );

  }, []);

  return (
    <Layout>
      <SEO
        title="Graphite Materials"
        description="Ceramark Technology Inc. supplies customized graphite
        product solutions in a wide range of custom specifications including:
        density, particle size, custom shaping, and more."
      />
      <Section1>
        <HeroWrapper>
          <HeroHeader ref={li => landingElements[0] = li}>
            Graphite Materials
          </HeroHeader>
          <HeroSubheader ref={li => landingElements[1] = li}>
            We provide customized solutions for a wide range of graphite product
            specifications
          </HeroSubheader>
          <HeroBody ref={li => landingElements[2] = li}>
            Graphite is a uniquely capable material: electrical conductivity under high temperature with excellent
            resistance to thermal shock and high compressive strength. Our customized graphite products are used in
            a variety of settings, from anodes to molds to dry lubricants, and can be built to your exact
            specifications, whatever your application.
          </HeroBody>
          <LandingList>
            <li ref={li => landingElements[3] = li}>
              We supply graphite blocks customized to your specifications: density, particle size, and all
              sizing and shaping requirements can be met.
            </li>
            <li ref={li => landingElements[4] = li}>
              The raw material we use is a highly purified graphite powder with excellent thermal conductivity and
              durability under high temperatures.
            </li>
            <li ref={li => landingElements[5] = li}>
              Our manufacturers have earned ISO9001, ISO14000, and ISO18000 certifications.
            </li>
          </LandingList>
          <CathodeDiv id="cathode-div" ref={li => landingElements[6] = li}/>
        </HeroWrapper>
      </Section1>
    </Layout>
  );
};

export default GraphiteElectrodePage;
